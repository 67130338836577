import React from 'react';
import { DadosFaturamentoContainer } from './styles';
import PaymentAccordion from '../../../PaymentAccordion';
import { TagLabel } from '../../../Tag';
import { paymentMethods } from '~/pages/MeuPlano/utils/paymentMethods';

export const DadosFaturamento: React.FC<{
  plano: string;
  tipo: 'MENSAL' | 'ANUAL';
}> = ({ plano, tipo }) => {
  return (
    <DadosFaturamentoContainer>
      <TagLabel label={`Plano selecionado: ${plano} ${tipo}`} />
      <div className="payment-accordion">
        <PaymentAccordion
          methods={paymentMethods}
          screen="dados-faturamento"
          lastCreditCard
        />
      </div>
    </DadosFaturamentoContainer>
  );
};
